@import './variables';

.modal-content{
  border: 0px !important;
}

.modal.fade .modal-dialog{
  transition: none;
}

@media (min-width: 576px){
  .modal-user-edit{
    max-width: 408px;
  } 
}

@media (max-width: $tablet-max-width){
  .modal-tablet-extract-signature{
    max-width: 720px;
  }
}


/*.backdrop-blur-filter {
  background: rgba(0, 20, 92, 0.5) !important;
  backdrop-filter: blur(3px) !important;
  mix-blend-mode: normal !important;
  opacity: 0.9 !important;
}

.modal-dialog {
  padding: 0px 16px;
}

.modal-dialog .modal-content {
  background: $color-gray000;
  box-sizing: border-box;
  border-radius: 8px;
  border: 0 !important;
  box-shadow: 0px 10px 20px -3px rgb(0 0 0 / 21%);
}


.modal-center {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.modal-dialog-centered {
  justify-content: center;
}

.modal-bg-solid-gray {
  background-color: #e5e5e5 !important;
  opacity: 1 !important;
}

.modal-bg-solid-blue{
  background-color: #eeeef6 !important;
  opacity: 1 !important;
}

.modal-content-assinar{
  width: 483px;
}



.modal-content-width-auto {
  .modal-content {
    width: auto !important;
  }
}

@media screen and (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    max-width: 100%;
    width: auto;
    margin: 0 auto;
    padding: 0;
  }
}

@media (max-width: 768px) {

  .modal {
    height: 93% !important;
    margin-top: 69px !important;

  }
}

.full-modal-menu {

  .modal {
    height: 93% !important;
    margin-top: 69px !important;

  }

  .modal-dialog {
    padding: 0px;
    margin-top: 00px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    max-width: 180px;
    width: 180px;

    .modal-content {
      height: 100vh;
      background: #001B7B;
      border: 0;
      box-sizing: border-box;
      border-radius: 0px;

      .modal-body {
        background: #001B7B;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
}


@media screen and (max-width: $tablet-max-width) {
  .modal-dialog{
    position: fixed;
    min-height: 100vh !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transition: none !important;
  }
  .modal-tablet-align-start {
    align-items: start !important;
    padding-top: 123px !important;
  }
}

@media screen and (max-width: $mobile-max-width) {
  .modal-dialog {
    margin: 0 !important;
  }

  .modal-mobile-align-start {
    align-items: start !important;
    padding-top: 72px !important;
  }

  .modal-content-assinar {
    width: 375px;
  }
}*/
